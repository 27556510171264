.navbar-container {
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 1000000;
}

.navbar-bottom {
  padding-left: 32px;
  padding-right: 90px;
  height: 100px;
}

.navbar-bottom .container-icon-hotline img {
  margin-right: 9px;
  width: 44px;
  height: 44px;
  margin-top: 20px;
}

.navbar-bottom .container-icon-hotline .info-hotline {
  font-size: 14px;
  color: #464c46;
}

.navbar-bottom .container-icon-hotline .content-info {
  color: #090c03;
  font-size: 14px;
}

/* Large Screen */
@media screen and (min-width: 600px) {
  .container-nav-info {
    width: 100%;
    height: 52px;
    background-color: #fd9344;
    padding: 0px 80px;
    display: flex;
    justify-content: flex-end;
  }

  .container-nav-info img {
    margin-right: 4px;
  }

  .container-nav-info .nav-content-info {
    margin-right: 32px;
  }

  .container-nav-info .nav-content-info span {
    font-size: 12px;
  }

  .container-content {
    padding: 3px 120px;
  }

  .content-info {
    color: #ffff;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    -webkit-text-decoration-line: none;
    text-decoration-line: none;
  }

  .content-info:hover {
    color: #ffffff;
  }

  .icon-nav {
    color: #ce4c36;
  }

  .container-nav-menu {
    height: 105px;
    width: 100%;
    padding: 20px 100px;
  }

  .logo-nav {
    width: 114px;
    height: 80px;
  }

  .nav-menu {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: #838694;
  }

  .info-hotline {
    font-size: 16px;
    color: #838694;
  }
}

/* Mobile Screen */
@media screen and (max-width: 600px) {
  .container-content,
  .nav-content-info,
  .icon-nav,
  .content-info,
  .container-nav-info,
  .navbar-hotline,
  .container-icon-hotline,
  .icon-hotline,
  .container-navbar-right,
  .container-hotline,
  .info-hotline,
  .content-info {
    display: none !important;
  }

  .navbar-bottom {
    height: 80px;
  }

  .logo-nav {
    height: 60px;
  }

  .nav-menu {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: #838694;
  }
}

@media screen and (max-width: 376px) {
  .container-content,
  .nav-content-info,
  .icon-nav,
  .content-info,
  .container-nav-info,
  .navbar-hotline,
  .container-icon-hotline,
  .icon-hotline,
  .container-navbar-right,
  .container-hotline,
  .info-hotline,
  .content-info {
    display: none;
  }

  .logo-nav {
    width: 114px;
    height: 80px;
  }

  .nav-menu {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: #838694;
  }
}

@media screen and (max-width: 361px) {
  .container-content,
  .nav-content-info,
  .icon-nav,
  .content-info,
  .container-nav-info,
  .navbar-hotline,
  .container-icon-hotline,
  .icon-hotline,
  .container-navbar-right,
  .container-hotline,
  .info-hotline,
  .content-info {
    display: none;
  }

  .logo-nav {
    width: 114px;
    height: 80px;
  }

  .nav-menu {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: #838694;
  }
}
