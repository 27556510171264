.list-stepform li {
  font-size: 16px;
  font-weight: 600;
}

@media screen and (min-width: 1000px) {
  .btn-verification {
    margin-right: 5rem;
  }
}

/* Mobile Size */
@media screen and (max-width: 420px) {
}

/* Mobile Size */
@media screen and (max-width: 376px) {
}

@media screen and (max-width: 360px) {
}
