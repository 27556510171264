.banner-enquiry {
  display: flex;
  width: 100%;
}

.banner-enquiry div:nth-child(1) {
  width: 46%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 63px;
  padding-bottom: 163px;
}

.banner-enquiry h4 {
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
}

.banner-enquiry p {
  font-size: 18px;
  line-height: 24px;
  margin-top: 20px;
}

.banner-enquiry div:nth-child(2) {
  width: 54%;
}

@media (max-width: 600px) {
  .banner-enquiry {
    flex-direction: column-reverse;
  }

  .banner-enquiry div:nth-child(1) {
    padding: 20px 40px;
    padding-bottom: 110px;
    width: 100%;
  }

  .banner-enquiry div:nth-child(2) {
    width: 100%;
  }

  .banner-enquiry h4 {
    font-size: 30px;
    line-height: 150%;
  }

  .banner-enquiry p {
    font-size: 16px;
    margin-top: 5px;
  }
}
