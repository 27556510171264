@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
* {
  font-family: "Poppins", sans-serif !important;
}

body {
  margin: 0;
  margin-top: 150px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form-group {
  margin-bottom: 14px;
}

.form-group label {
  font-weight: 600;
  color: #4a4c46;
  font-size: 16px;
}

.form-group input,
.form-group textarea {
  border-radius: 6px;
  transition: 0.2s;
  border: 1px solid #b0b1ae !important;
}

.form-group input {
  height: 41px;
}

.form-group input:hover,
.form-group input:focus,
.form-group textarea:hover,
.form-group textarea:focus {
  background-color: #ffeee1;
  outline: none !important;
  box-shadow: nonw !important;
  outline-offset: none !important;
}

.form-group .form-control:focus {
  box-shadow: none !important;
}

.form-group input:disabled:hover,
.form-group textarea:disabled:hover {
  background-color: #e9ecef;
}

.form-group textarea:hover,
.form-group textarea:focus {
  border: 1px solid #b0b1ae !important;
}

.MuiCalendarPicker-root .MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
  color: #fff !important;
  background-color: #fd9344 !important;
}

.MuiTypography-root.MuiTypography-h4.title-section {
  color: #4a4c46;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 8px;
}

.section-body {
  padding: 0px 35px;
}

@media (max-width: 600px) {
  body {
    margin-top: 80px;
  }
  .MuiTypography-root.MuiTypography-h4.title-section {
    font-size: 18px;
    line-height: 150%;
  }
  .form-group label.form-label {
    font-size: 14px;
    font-weight: 500;
  }

  .section-body {
    padding: 0px;
  }
}
iframe {
  z-index: -1000000 !important;
}
