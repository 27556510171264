.title-signup-detail {
  color: #0275d8;
  padding-bottom: 15px;
  border-bottom: 1px solid #0275d8;
}

.border-signup-detail {
  margin: 14px 0px;
  padding: 0px;
  border-bottom: 1px solid #dedede;
}
